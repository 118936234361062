exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dev-404-page-tsx": () => import("./../../../src/pages/dev-404-page.tsx" /* webpackChunkName: "component---src-pages-dev-404-page-tsx" */),
  "component---src-templates-accommodation-page-index-tsx": () => import("./../../../src/templates/AccommodationPage/index.tsx" /* webpackChunkName: "component---src-templates-accommodation-page-index-tsx" */),
  "component---src-templates-accommodations-page-index-tsx": () => import("./../../../src/templates/AccommodationsPage/index.tsx" /* webpackChunkName: "component---src-templates-accommodations-page-index-tsx" */),
  "component---src-templates-bar-page-index-tsx": () => import("./../../../src/templates/BarPage/index.tsx" /* webpackChunkName: "component---src-templates-bar-page-index-tsx" */),
  "component---src-templates-breakfast-page-index-tsx": () => import("./../../../src/templates/BreakfastPage/index.tsx" /* webpackChunkName: "component---src-templates-breakfast-page-index-tsx" */),
  "component---src-templates-contacts-page-index-tsx": () => import("./../../../src/templates/ContactsPage/index.tsx" /* webpackChunkName: "component---src-templates-contacts-page-index-tsx" */),
  "component---src-templates-deals-page-index-tsx": () => import("./../../../src/templates/DealsPage/index.tsx" /* webpackChunkName: "component---src-templates-deals-page-index-tsx" */),
  "component---src-templates-gallery-page-index-tsx": () => import("./../../../src/templates/GalleryPage/index.tsx" /* webpackChunkName: "component---src-templates-gallery-page-index-tsx" */),
  "component---src-templates-golf-club-page-index-tsx": () => import("./../../../src/templates/GolfClubPage/index.tsx" /* webpackChunkName: "component---src-templates-golf-club-page-index-tsx" */),
  "component---src-templates-home-page-index-tsx": () => import("./../../../src/templates/HomePage/index.tsx" /* webpackChunkName: "component---src-templates-home-page-index-tsx" */),
  "component---src-templates-location-page-index-tsx": () => import("./../../../src/templates/LocationPage/index.tsx" /* webpackChunkName: "component---src-templates-location-page-index-tsx" */),
  "component---src-templates-services-page-index-tsx": () => import("./../../../src/templates/ServicesPage/index.tsx" /* webpackChunkName: "component---src-templates-services-page-index-tsx" */)
}

